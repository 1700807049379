import { Injectable } from '@angular/core';
import { Subject, map, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class UtilsService {
    subsject = new Subject()
    constructor() { }

    hasDatePassed(dateString: string) {
        let now = new Date();
        let dateToCheck = new Date(dateString);
        return now > dateToCheck;
    }

    // async handleLogin() {
    //     if (environment.name === "local") return;
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const oboToken = urlParams.get('access_token');

    //     // if (this.authService.isLoggedIn()) {
    //     //     const userData = this.authService.getUserData();
            
    //     //     if (userData?.expiresIn && this.hasDatePassed(userData.expiresIn)) {
    //     //         localStorage.clear();
    //     //         if (oboToken) {
    //     //             try {
    //     //                 const response = await this.authenticate(oboToken);
    //     //                 return response 
    //     //             } catch (error) {
    //     //                 this.router.navigate(['/error'], { queryParams: { errorCode: 401 } });
    //     //                 return;
    //     //             }
    //     //         } else {
    //     //             this.router.navigate(['/error'], { queryParams: { errorCode: 400 } })
    //     //             return;
    //     //         };
    //     //     } else return;
    //     // } else {
    //     //     if (oboToken) {
    //     //         try {
    //     //             const response = await this.authenticate(oboToken);
    //     //             return response
    //     //         } catch (error) {
    //     //             this.router.navigate(['/error'], { queryParams: { errorCode: 401 } });
    //     //             return;
    //     //         }
    //     //     }
    //     //     else {
    //     //         this.router.navigate(['/error'], { queryParams: { errorCode: 400 } });
    //     //         return;
    //     //     }
    //     //     // return this.authenticate(oboToken);
    //     // }
    // }

    // async authenticate(accessToken: string) {
    //     // return await this.authService.login(accessToken).toPromise();
    // }
}