import { Injectable } from '@angular/core';
import { Observable, tap, firstValueFrom } from 'rxjs';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { AuthConfig, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { UserInformation } from '../models/user-information.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthResponse } from './auth.model';

// Other imports...

@Injectable({providedIn: 'root'})
export class AuthService {
  private authConfig: AuthConfig = environment.authConfig;
  constructor(private http: HttpClient, private oauthService: OAuthService) {
    const urlParams = new URLSearchParams(window.location.search);
    const oboToken = urlParams.get('access_token');
    if (!oboToken) {
      this.oauthService.configure(this.authConfig);
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
      this.oauthService.loadDiscoveryDocumentAndTryLogin().then((value) => {
        if (!this.oauthService.hasValidAccessToken()) {
          this.oauthService.initCodeFlow();
        }
      })
    }
  }

  public login(): void {
    this.oauthService.initCodeFlow();
  }

  public tryLogin() {
    return this.oauthService.tryLogin();
  }

  public logout(): void {
    this.oauthService.logOut();
  }

  public getIdentityClaims() {
    return this.oauthService.getIdentityClaims() as UserInformation;
  }

  public isAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }
  public isAuthenticatedOBO(): boolean {
    return !!localStorage.getItem('accessTokenOBO');
  }
  public authenticationEvents(): Observable<OAuthEvent> {
    return this.oauthService.events;
  }

  getOBOUserData(accessToken: string) {
    const request = { accessToken };
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')

    return firstValueFrom(this.http.post<AuthResponse>('/get-token-obo', request, { headers }).pipe(tap((response) => {
      localStorage.setItem('authResult', JSON.stringify(response));
      localStorage.setItem('accessTokenOBO', response.accessToken);
    })));
  }

  getOBOUserDataStorage() {
    let responseString = localStorage.getItem('authResult');
    if (responseString !== null) {
      let response: AuthResponse = JSON.parse(responseString);
      return response;
    } else return null    
  }
}
