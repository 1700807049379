<div class="row mt-5">
  <div class="col-12 text-center">
    <div class="card border-0 m-1" id="statusSuccessCard" style="display: none;">
      <div class="card-body">
        <h1 class="text-center">Success! You will be redirected in 5 seconds...</h1>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
          <circle class="path circle" fill="none" stroke="#198754" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
          <polyline class="path check" fill="none" stroke="#198754" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " /> 
        </svg>
      </div>
    </div>
    <div class="card border-0 m-1" id="statusErrorCard" id="statusErrorCard" style="display: none;">
      <div class="card-body">
        <h1 class="text-center">Error! You will be redirected in 5 seconds...</h1>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
          <circle class="path circle" fill="none" stroke="#db3646" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" /> 
          <line class="path line" fill="none" stroke="#db3646" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
          <line class="path line" fill="none" stroke="#db3646" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" X2="34.4" y2="92.2" /> 
        </svg>
      </div>
    </div>
  </div>
</div>

<div class="main-container mt-5">
  <div class="loading-container" *ngIf="isLoading">
    <h1 class="text-center">Checking token for authentication</h1>

    <div class="d-flex justify-content-center">
      <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
        <circle cx="170" cy="170" r="160" stroke="#E2007C"/>
        <circle cx="170" cy="170" r="135" stroke="#404041"/>
        <circle cx="170" cy="170" r="110" stroke="#E2007C"/>
        <circle cx="170" cy="170" r="85" stroke="#404041"/>
      </svg>
    </div>
  </div>
</div>