import { inject } from "@angular/core";
import { Router, CanActivateFn } from "@angular/router";
import { AuthService } from "./auth.service";

export const authGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const urlParams = new URLSearchParams(window.location.search);
  const oboToken = urlParams.get('access_token');

  if (!oboToken) {
    if (!authService.isAuthenticated()) {
      authService.login();
      return false;
    }
  } else {
    if (!authService.isAuthenticated()) {
      const userData = await authService.getOBOUserData(oboToken);
      if (!userData) return false;
    }
  }

  return true;
};
