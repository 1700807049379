import { AuthService } from './../../auth/auth.service';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
    HttpClient,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs';
import { NotificationPreferencesRequest } from 'src/app/models/notification-preferences.model';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { UtilsService } from 'src/app/utils/utils.service';
import { AuthResponse } from 'src/app/auth/auth.model';


type ProfileType = {
    givenName?: string,
    surname?: string,
    userPrincipalName?: string,
    id?: string
};

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
    isLoading = true;
    isLoggedIn = false;
    userData: AuthResponse | null = null;
    constructor(private utilsService: UtilsService, private authService: AuthService, private router: Router, private oauthService: OAuthService) {}

    async ngOnInit(){
        const urlParams = new URLSearchParams(window.location.search);
        const oboToken = urlParams.get('access_token');

        if (!oboToken) {
            this.authService.authenticationEvents().subscribe(event => {
                if (event instanceof OAuthErrorEvent) {
                  this.isLoading = false;
                  console.error('OAuthErrorEvent Object:', event);
                  this.router.navigate(['/error'], { queryParams: { errorCode: 500 } });
                }
                if (event.type === 'token_refreshed') {
                    const msad_groups: string[] = this.authService.getIdentityClaims().msad_groups;
                    const isAgentAdvocate = msad_groups.find((value) => {
                        if (value.includes("WFMO_Notify_Agents_Advocates")) return true;
                        else return false;
                    });
                    if (isAgentAdvocate) this.resolve("directreports");
                    else this.resolve("notification-preferences");
                }
              });
            if (this.authService.isAuthenticated()) {
                const msad_groups: string[] = this.authService.getIdentityClaims().msad_groups;
                const isAgentAdvocate = msad_groups.find((value) => {
                    if (value.includes("WFMO_Notify_Agents_Advocates")) return true;
                    else return false;
                });
                if (isAgentAdvocate) this.resolve("directreports");
                else this.resolve("notification-preferences");
            } 
        } else {
            if (this.authService.isAuthenticatedOBO()) {
                this.userData = this.authService.getOBOUserDataStorage();
                this.resolve('notification-preferences');
            } else {
                this.userData = await this.authService.getOBOUserData(oboToken);
                this.resolve('notification-preferences');
            }
        }

    }

    resolve(path: String) {
        this.isLoading = false;
        if (path === "error") this.showCard('statusErrorCard');
        else this.showCard('statusSuccessCard');
        
        setTimeout(() => {
            this.router.navigate([path])
        }, 5000);
    }

    showCard(elementId: string) {
        const element = document.getElementById(elementId);
        if (element) {
            element.style.display = 'block'
        }
    }
}