import { environment } from './../environments/environment';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NavbarComponent } from './components/navbar/navbar.component';
import {MatDialogModule} from '@angular/material/dialog'; 
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import { DirectreportsComponent } from './components/directreports/directreports.component';
import { ModalComponent } from './components/modal/modal.component';
import { TreeviewModule } from '@samotics/ngx-treeview';
import { OAuthModule, ValidationHandler } from 'angular-oauth2-oidc'; 
import { MatToolbarModule } from '@angular/material/toolbar';
// import { AuthInterceptor } from './auth/auth.interceptor';
import { NotificationPreferencesComponent } from './components/notification-preferences/notification-preferences.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    DirectreportsComponent,
    ModalComponent,
    NotificationPreferencesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,  
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,  
    NzCheckboxModule,
    NzInputModule,
    NzSwitchModule,
    NzTimePickerModule,
    NzButtonModule,
    NzToolTipModule,
    MatDialogModule, 
    MatButtonModule,
    NgSelectModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    OAuthModule.forRoot(),
    TreeviewModule.forRoot(),
    // MsalModule.forRoot(msalInstance, {
    //   interactionType: InteractionType.Redirect,
    //   authRequest: {
    //     scopes: ['User.Read']
    //   }
    // }, msalInterceptorConfig)
  ],
  providers: [
    { provide: ValidationHandler, useClass: JwksValidationHandler },
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent], 
  
})
export class AppModule { }
