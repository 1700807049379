import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  errorMessage = '';
  redirectUrl = '';
  buttonText = '';
  errorCode: null | string = null;
  constructor(private route: ActivatedRoute) {}
  
  ngOnInit(): void {
    this.errorCode = this.route.snapshot.queryParamMap.get('errorCode');
    switch (this.errorCode) {
      case '400':
        this.errorMessage = 'Error... no token provided in url for authentication, please log in through SPARQ';
        this.redirectUrl = 'TODO';
        this.buttonText = 'Home'
        break;

      case '401':
        this.errorMessage = 'Hummm... It seems your token is invalid or has expired, please log in through SPARQ';
        this.redirectUrl = 'TODO';
        this.buttonText = 'Home'
        break;
      case '500':
        this.errorMessage = 'Hummm... It seems an unexpected error has occured';
        this.redirectUrl = 'TODO';
        this.buttonText = 'Home'
        break;
    
      default:
        this.errorMessage = 'An error occurred during the authentication process';
        this.redirectUrl = 'TODO';
        this.buttonText = 'Home'
        break;
    }
  }
  
}
