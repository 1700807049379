
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';

import { DirectreportsComponent } from './components/directreports/directreports.component';
import { ErrorComponent } from './components/error/error.component';
import { authGuard } from './auth/auth.guard';
import { NotificationPreferencesComponent } from './components/notification-preferences/notification-preferences.component';

const routes: Routes = [

  { path: 'agent' , component: NotificationPreferencesComponent, canActivate: [authGuard] } ,
  { path: 'manager', component: NotificationPreferencesComponent, canActivate: [authGuard] },
  { path: 'notification-preferences', component: NotificationPreferencesComponent, canActivate: [authGuard] },
  { path: '', component: HomeComponent }, 
  { path: 'directreports', component: DirectreportsComponent, canActivate: [authGuard] },
  { path: 'error', component: ErrorComponent }, 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {

 }
