import { TreeviewItem } from "@samotics/ngx-treeview";

export class Constants {
    public static localBackendUrl = "http://localhost:8080/WFMobility"
    public static treeItemsBlue: TreeviewItem[] = [

      new TreeviewItem({
        text: 'MU',
        value: 1,
        collapsed: true,
        children: [
          { text: 'Child 1.1',
          value: 11,
          children: [
           { text: 'Agent #1',
            value: 11 },
            {text: 'Agent #2',
            value: 11}
          ] },
          { text: 'Child 1.2', value: 12 },
        ],
      }),
        new TreeviewItem({
          text: 'MU Set',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Primary CT',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Primary WT',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Location',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Site Building Code',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Primary CT',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Speciality Skill 1',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Speciality Skill 2',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Team Specific 1',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Team Specific 2',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Team Specific 3',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Team Specific 4',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Team Specific 5',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Team Specific 6',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Team Specific 7',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Team Specific 8',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Team Specific 9',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Team Specific 10',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'WFM Team Code',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'BUS_CD_DESCR',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Director',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Supervisor',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Business Manager',
          value: 2,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
      ];

    public static treeItemsOrange: TreeviewItem[] = [
        new TreeviewItem({
          text: 'MU or MU Set',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Job Function 1',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Job Function 2',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Job Function 3',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Job Function 4',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Job Function 5',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Job Function 6',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Job Function 7',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Job Function 8',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Job Function 9',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Job Function 10',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Division Code',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Telecommuter',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'RTI Network Login ID',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Hours',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Location Facility',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Grade',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'RTI Network Domain',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'GL_Code',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Employee ID',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Date of Hire',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'MSID',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Business Manager',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'FTE',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Region',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Supervisor',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Work Status',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
        new TreeviewItem({
          text: 'Location City',
          value: 1,
          collapsed: true,
          children: [
            { text: 'Child 1.1',
            value: 11,
            children: [
             { text: 'Agent #1',
              value: 11 },
              {text: 'Agent #2',
              value: 11}
            ] },
            { text: 'Child 1.2', value: 12 },
          ],
        }),
      ];
    public static ORANGE_ITEMS = [
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 1', value: '2' }, { name: 'Option 2', value: '3' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 5', value: '5' }, { name: 'Option 6', value: '6' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 8', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: []},
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 7', value: '7' }, { name: 'Option 8', value: '8' }], selectedOptions: [] },
      { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    ];
    public static BLUE_ITEMS = [
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    ]
    public static BLUE_ARRAY = [
      "MU", "MU Set", "Business Manager", "BUS_CD_DESCR", "Director", "Primary CT", "Primary WT", "Site", "Site Building Code", "Specialty Skill 1", "Speciality Skill 2", "Supervisor", "Team Specific 1", "Team Specific 2", "Team Specific 3","Team Specific 4","Team Specific 5","Team Specific 6","Team Specific 7","Team Specific 8","Team Specific 9","Team Specific 10", "WFM Team Code","Location"
    ]
    public static ORANGE_ARRAY = [
      "MU", "MU Set", "Business Manager", "Date of Hire", "Division Code","Telecommuter", "Employee ID", "FTE", "Grade", "Hours", "Job Function 1", "Job Function 2", "Job Function 3", "Job Function 4", "Job Function 5", "Job Function 6", "Job Function 7", "Job Function 8", "Job Function 9", "Job Function 10", "Location City", "MSID", "Region", "RTI Network Domain", "RTI Network Login ID","GL_Code ", "Supervisor", "Work Status", "Location Facility"
    ]

    public static MY_TEAM_ITEMS = [
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    { options: [{ name: 'Select All', value: 'all' }, { name: 'Option 3', value: '3' }, { name: 'Option 4', value: '4' }], selectedOptions: [] },
    ];

    public static MY_TEAM_ARRAY = [
      "Option 1", "Option 2", "Option 3", "Option 4"
    ]
}
