import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NotificationPreferencesRequest} from '../models/notification-preferences.model';
import {Observable} from 'rxjs';
import {sendMessageRequest} from '../models/send-message.model';
import {getMessagesCountResponse} from '../models/get-messages-count.model';
import {sentMessage} from '../models/sent-message.model';
import {draftMessage} from '../models/draft-message.model';

@Injectable({
  providedIn: 'root'
})
export class BackendService {


  private baseUrl = 'https://api-stg.uhg.com/api/optum-technology-as-a-business/workforce-management/wfmobility/v0/WFMobility/';
  //private localHostUrl = 'http://localhost:8080/WFMobility';
  private localHostUrl = 'http://localhost:8080';

  
  constructor(private httpClient: HttpClient) {
  }

  getEmailandPhonenumber(employeeId: string) {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    const request = { employeeId: '000000556' };

    return this.httpClient.post<any>(`/get-email-and-phone`, request, {
      headers
    });
  }

  saveNotificationPreferences(request: NotificationPreferencesRequest): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')

    return this.httpClient.post<any>(`/saveNotificationPreferences`, request, {
      headers
    });
  }

  sendMessage(request: sendMessageRequest) {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')

    return this.httpClient.post<void>(`/sendMessage`, request, {
      headers
    });
  }

  getSentMessages(employeeId: String): Observable<sentMessage[]> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
    return this.httpClient.get<sentMessage[]>(`/getSentMessages?employeeId=${employeeId}`, {headers});
  }

  getDraftMessages(employeeId: String): Observable<draftMessage[]> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
    return this.httpClient.get<draftMessage[]>(`/getDraftMessages?employeeId=${employeeId}`, {headers});
  }

  getRole(employeeId: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
    return this.httpClient.get<any>(`${this.localHostUrl}getRole?employeeId=${employeeId}`, {headers});
  }

  getInstance(employeeId: String): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
    return this.httpClient.get<any>(`${this.localHostUrl}getInstance?employeeId=${employeeId}`, {headers});
  }

  getMessagesCount(employeeId: String): Observable<getMessagesCountResponse> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
    return this.httpClient.get<getMessagesCountResponse>(`/getMessagesCount?employeeId=${employeeId}`, {headers});
  }

  getFilters(filter: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
    const url = `${this.baseUrl}getAgentData?filter=${filter}&page=0&size=100000&search=`;
    return this.httpClient.get<any>(url, {headers});
  }

  getAgents(selected: string) {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
    return this.httpClient.post<any []>(`${this.baseUrl}getAgents?page=0&size=1000000`, selected, {
      headers
    });
  }

  
  getReportes(employeeId: string) {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
    return this.httpClient.get<any []>(`${this.baseUrl}getReporters?employeeId=${employeeId}`, {
      headers
    });
  }
  

  deleteDraftMessage(messageId: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
    const url = `${this.baseUrl}deleteDraftMessage?messageId=${messageId}`;
    return this.httpClient.get<any>(url, {headers});
  }
}



