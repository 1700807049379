<div *ngIf="!isLoading" class="main-container">
    <h1>Select your preferred channel(s) below</h1>
    <div class="form-container">
      <form [formGroup]="myForm" class="">
        <div class="preferences-container">

          <!-- IVR Checkbox -->
          <div class="input-container">
            <div class="label-container">

              <label class="input-label-container"> <span nz-checkbox formControlName="ivr" nzValue="ivr">IVR</span>
              </label>

              <!-- <span nz-tooltip nzTooltipTitle="You can also be notified in the Sparq app. Once downloaded, open the app and go to Settings > Notifications."> <mat-icon >info</mat-icon> </span>  -->
            </div>

            <!-- <mat-icon
              matTooltip="You can also be notified in the Sparq app. Once downloaded, open the app and go to Settings > Notifications.">info</mat-icon> -->

            <input nz-input disabled nzSize="default" type="text"
            value="{{phoneNumber}}" class="text-input" />
          </div>


          <!-- SMS Checkbox -->
          <div class="input-container">
            <div class="label-container">

              <label class="input-label-container">
                <span nz-checkbox formControlName="sms" nzValue="sms">SMS</span>
              </label>

              <!-- <span nz-tooltip nzTooltipTitle="If the number displayed is incorrect, click here to update your number in GSS"> <mat-icon >info</mat-icon> </span>  -->
              <!-- <mat-icon aria-label="You can also be notified in the Sparq app" -->
                <!-- matTooltip="You can also be notified in the Sparq app. Once downloaded, open the app and go to Settings > Notifications.">info</mat-icon> -->
            </div>

            <input nz-input disabled nzSize="default" type="text"
            value="{{phoneNumber}}" class="text-input" />
          </div>

          <!-- Email Checkbox -->
          <div class="input-container">
            <div class="label-container">

              <label class="input-label-container">
                <span nz-checkbox formControlName="email" nzValue="email">Email</span>
              </label>

              <!-- <span nz-tooltip nzTooltipTitle="If the number displayed is incorrect, click here to update your number in GSS"> <mat-icon >info</mat-icon> </span>  -->
              <!-- <mat-icon aria-label="You can also be notified in the Sparq app" -->
                <!-- matTooltip="You can also be notified in the Sparq app. Once downloaded, open the app and go to Settings > Notifications.">info</mat-icon> -->
            </div>

            <input nz-input disabled nzSize="default" type="text"
            value="{{email}}" class="text-input" />
          </div>
          <div >
            If any contact information is incorrect,  click <a href="#" (onclick)="updateContactInfo(event, 'https://globalselfservice.uhg.com/psp/pshpr01/EMPLOYEE/HRMS/c/ROLE_EMPLOYEE.HR_EMAIL_ADDRESSES.GBL?NAVSTACK=Clear&pslnkid=UHC_HR_EMAIL_ADDRESSES_GBL&PORTALPARAM_PTCNAV=UHC_HR_EMAIL_ADDRESSES_GBL&EOPP.SCNode=HRMS&EOPP.SCPortal=EMPLOYEE&EOPP.SCName=ADMN_EMPLOYEE_QUICK_LINKS&EOPP.SCLabel=Employee%20Quick%20Links&EOPP.SCPTcname=PT_PTPP_SCFNAV_BASEPAGE_SCR&FolderPath=PORTAL_ROOT_OBJECT.PORTAL_BASE_DATA.CO_NAVIGATION_COLLECTIONS.ADMN_EMPLOYEE_QUICK_LINKS.UHC_HR_EMAIL_ADDRESSES_GBL&IsFolder=false')">
            here
            </a>to update it in GSS.
          </div>

          <div class="spark-label-container">
              <b>SparqGo -</b>
              <i> You can also be notified in the SparqGo app.
                <a href="#" (click)="updateContactInfo(event,'https://sparq.uhg.com/')">
                  Once downloaded,
                  </a>
                 open the app and go to Settings > Notifications.  </i>

          </div>
        </div>
        <div class="desired-days-container">
          <h3>What are your desired Days/Times?</h3>


          <div class="radio-container">
            <label class = "radio-text">
              <input type="radio" name="timeOption" value="anytime" formControlName="timeOption"> Anytime
          </label>
            <label class = "radio-text">
                <input type="radio" name="timeOption" value="custom" formControlName="timeOption"> Custom
            </label>
            <label class = "radio-text">
                <input type="radio" name="timeOption" value="weekdays" formControlName="timeOption"> Weekdays
            </label>
        </div>

        <div>
        <p>Note: Time selected will be in your device's time zone</p>
        </div>
        <div *ngIf="myForm?.get('anytime')?.value">
          <p>By selecting "Anytime", you may receive notifications at any hour, including outside of regular business hours.</p>
        </div>
        </div>


        <div class="day-time-container" *ngIf="myForm?.get('timeOption')?.value !== 'anytime'">
          <div *ngFor="let day of days" class="time-picker-container">
            <div class="time-picker-container" *ngIf="myForm.get('timeOption')?.value !== 'weekdays' || (day.name !== 'Sunday' && day.name !== 'Saturday')">
              <!-- Day name -->
              <div class="day-name">{{ day.name }}</div>
              <div class="time-windows-container" *ngFor="let timeWindow of day.timeWindows; let i = index">
                <!-- Wrap elements in a flex container -->
                <div style="display: flex; align-items: center;">
                  <!-- Day Checkboxes -->
                  <div class="day-container" style="margin-right: 10px;">
                    <label nz-checkbox [formControlName]="day.name">{{ day.label }}</label>
                  </div>
                  <div>
                    <nz-time-picker [formControlName]="day.name + '-startTime-' + i" [nzDisabled]="!myForm.value[day.name]"
                      [nzMinuteStep]="30" [nzFormat]="'HH:mm'" [ngClass]="{'red-border': myForm.value[day.name] && !myForm.value[day.name + '-startTime-' + i]}"></nz-time-picker>
                    <span style="margin: 0 7px;">to</span>
                    <nz-time-picker [formControlName]="day.name + '-endTime-' + i" [nzDisabled]="!myForm.value[day.name]"
                      [nzMinuteStep]="30" [nzFormat]="'HH:mm'" [ngClass]="{'red-border': myForm.value[day.name] && !myForm.value[day.name + '-endTime-' + i]}"></nz-time-picker>
                  </div>
                  <!-- Copy/Paste Buttons -->
                  <div class="stored-save-send-container">
                    <ng-template #copiedTooltip>
                      <span>Copied!</span>
                    </ng-template>
                    <button class="stored-save-send-buttons" nz-tooltip nzTooltipTitle="Copied!" nzTooltipTrigger="click" (click)="copy(day, i); copied = true;">Copy</button>
                    <button class="stored-save-send-buttons" (click)="paste(day,i)">Paste</button>
                  </div>
                </div>
                <button nz-button nzType="default" (click)="removeTimeWindow(day, i)" [disabled]="!myForm.value[day.name]"
                  *ngIf="day.timeWindows.length > 1">-</button>
              </div>
            </div>
          </div>
        </div>



        <div class="submit-button">
          <button nz-button nzType="primary" [disabled]="
                    !(
                      myForm.value.sparq ||
                      myForm.value.sms ||
                      myForm.value.email ||
                      myForm.value.ivr
                    )
                  " (click)="onSubmit()" (click)="openMessage()"
                  [nz-tooltip]="!myForm.value.sparq || !myForm.value.sms || !myForm.value.email || !myForm.value.ivr ? 'Submit button will become available when contact information and desired timeframes are entered.' : null">
            Submit
          </button>
        </div>



        <div class="modal" id="myModal" *ngIf="myForm.value.sparq">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Alert message</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                Users will need to go to the SparqGo app to enable push notifications.
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="CloseMessage()"
                  data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
</div>

<div *ngIf="isLoading" class="main-container mt-5">
  <div class="loading-container" >
    <div class="d-flex justify-content-center">
      <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
        <circle cx="170" cy="170" r="160" stroke="#E2007C"/>
        <circle cx="170" cy="170" r="135" stroke="#404041"/>
        <circle cx="170" cy="170" r="110" stroke="#E2007C"/>
        <circle cx="170" cy="170" r="85" stroke="#404041"/>
      </svg>
    </div>
   </div>
</div>
