<div *ngIf="!isLoading" class="main-container">
  <h1 id="mainTitle">Direct Agent Notification(s)</h1>

  <div class="d-grid gap-4">
    <div class="justify-content-center"></div>
    <button
      (click)="openModal('storedMessageSelection')"
      class="btn btn-primary col-4"
    >
      Create New Message
    </button>
    <button
      id="draftsBtn"
      (click)="openModal('draftsModal')"
      class="btn btn-primary col-4"
    >
      Drafts ({{ draftsCount }})
    </button>
    <button
      id="sentBtn"
      (click)="openModal('sentModal')"
      class="btn btn-primary col-4"
    >
      Sent ({{ sentCount }})
    </button>
  </div>

  <!-- Modal for Stored Messages is clicked showing the stored message selection options-->
  <div class="listbox-container">
    <div id="storedMessageSelection" class="modal">
      <div class="modal-content">
        <span class="close" (click)="closeAllModals('storedMessageSelection')"
        >&times;</span
        >
        <h2 id="mainTitle">Select Option</h2>
        <div class="modal-body">
          <div class="stored-selection-container">
            <button
              (click)="storedMessageInputPopup('extraTime', '')"
              class="btn stored-selection-buttons"
            >
              Extra Time/OT is Available
            </button>
            <button
              (click)="storedMessageInputPopup('vto', '')"
              class="btn stored-selection-buttons"
            >
              VTO is Available
            </button>
            <button
              (click)="storedMessageInputPopup('sysOutage', '')"
              class="btn stored-selection-buttons"
            >
              System Outage Notification
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal for when Stored Message Selection Option is Clicked-->
  <div class="listbox-container">
    <div class="modal" id="InputStoredMessageModal">
      <div class="modal-content">
        <div class="stored-header">
          <h5 class="stored-header-text">{{ modalTitle }}</h5>
          <span
            class="close"
            (click)="closeStoredMessageInputPopup()"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </span>
        </div>
        <div class="modal-body">
          <div
            *ngIf="selectedAction === 'extraTime'"
            class="stored-message-text"
          >
            Extra Time/Overtime is available on
            <div class="cs-form">
              <input
                type="date"
                class="form-control"
                [(ngModel)]="selectedDate"
                id="inputedDate"
                (change)="updateDate($event); updateSelectedMessage()"
              />
            </div>
            <div *ngIf="invalidDateInput" class="invalid-input">
              *Cannot choose a day from the past*
            </div>
            between
            <div class="cs-form">
              <input
                type="time"
                step="300"
                class="form-control"
                [(ngModel)]="selectedStartTime"
                (change)="updateStartTime($event); updateSelectedMessage()"
              />
            </div>
            <div *ngIf="invalidTimeInput" class="invalid-input">
              *Start time must be before end time*
            </div>
            and
            <div class="cs-form">
              <input
                type="time"
                class="form-control"
                [(ngModel)]="selectedEndTime"
                (change)="updateEndTime($event); updateSelectedMessage()"
              />
            </div>
            (CST). Please submit schedule change requests for review.
            <span [innerHTML]="workStreamText"></span>
          </div>
          <div *ngIf="selectedAction === 'vto'" class="stored-message-text">
            Voluntary Time Off is available on
            <div class="cs-form">
              <input
                type="date"
                class="form-control"
                [(ngModel)]="selectedDate"
                id="inputedDate"
                (change)="updateDate($event); updateSelectedMessage()"
              />
            </div>
            <div *ngIf="invalidDateInput" class="invalid-input">
              *Cannot choose a day from the past*
            </div>
            between
            <div class="cs-form">
              <input
                type="time"
                class="form-control"
                [(ngModel)]="selectedStartTime"
                (change)="updateStartTime($event); updateSelectedMessage()"
              />
            </div>
            <div *ngIf="invalidTimeInput" class="invalid-input">
              *Start time must be before end time*
            </div>
            and
            <div class="cs-form">
              <input
                type="time"
                class="form-control"
                [(ngModel)]="selectedEndTime"
                (change)="updateEndTime($event); updateSelectedMessage()"
              />
            </div>
            (CST). Please submit schedule change requests for review.
            <span [innerHTML]="workStreamText"></span>
          </div>
          <div
            *ngIf="selectedAction === 'sysOutage'"
            class="stored-message-text"
          >
            We are aware of an active system outage for
            <select
              [(ngModel)]="selectedApplication"
              class="application-input"
              (change)="updateSelectedMessage()"
            >
              <option
                *ngFor="let app of applications; let i = index"
                [value]="app"
                [selected]="selectedApplication"
              >
                {{ app }}
              </option>
            </select>
            . You no longer need to contact the Help Desk to advise
          </div>
          <div
            *ngIf="selectedAction === 'vto' || selectedAction === 'extraTime'"
            class="stored-save-send-container"
          >
            <button
              class="stored-save-send-buttons"
              (click)="closeAllModalsBack('InputStoredMessageModal'); openModal('storedMessageSelection')"
              id="back"
            >
              Back
            </button>
            <!--<div *ngIf="role === 'WFM'">-->
              <button
                class="stored-save-send-buttons"
                [disabled]="(selectedAction === 'vto' && !isVtoValid()) || (selectedAction === 'extraTime' && !isExtraTimeValid())"
                [ngClass]="{'grey-out': (selectedAction === 'vto' && !isVtoValid()) || (selectedAction === 'extraTime' && !isExtraTimeValid())}"
                (click)="closeModal('InputStoredMessageModal');  openModal('filterModal')"
                id="next"
              >
                Next
              </button>
            <!--</div>-->
            <!--<div *ngIf="role !== 'WFM'">-->
              <button
                class="stored-save-send-buttons"
                [disabled]="!selectedApplication"
                [ngClass]="{'grey-out': !selectedApplication}"
                (click)="closeModal('InputStoredMessageModal'); openModal('myTeamModal')"
                id="nextMyTeam"
              >
                Next myTeam
              </button>
            <!--</div>-->
            <ng-template #SendMessage>
            </ng-template>
          </div>
          <div
            *ngIf="selectedAction === 'sysOutage'"
            class="stored-save-send-container"
          >
            <button
              class="stored-save-send-buttons"
              (click)="closeAllModalsBack('InputStoredMessageModal'); openModal('storedMessageSelection')"
              id="back"
            >
              Back
            </button>
            <!--<div *ngIf="role === 'WFM'">-->
              <button
                class="stored-save-send-buttons"
                [disabled]="!selectedApplication"
                [ngClass]="{'grey-out': !selectedApplication}"
                (click)="closeModal('InputStoredMessageModal'); openModal('filterModal')"
                id="next"
              >
                Next
              </button>
            <!--</div>-->
            <!--<div *ngIf="role !== 'WFM'">-->
              <button
                class="stored-save-send-buttons"
                [disabled]="!selectedApplication"
                [ngClass]="{'grey-out': !selectedApplication}"
                (click)="closeModal('InputStoredMessageModal'); openModal('myTeamModal')"
                id="nextMyTeam"
              >
                Next myTeam
              </button>
            <!--</div>--> 

            <ng-template #SendMessage></ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Modal for when Drafts is Clicked-->
  <div id="draftsModal" class="modal">
    <div class="modal-content">
      <span class="close" (click)="closeModal('draftsModal')">×</span>
      <h1>Drafts</h1>
      <div class="list-group">
        <!-- Show this message if no drafts are saved-->
        <ng-container *ngIf="drafts.length === 0">
          <p>No drafts saved</p>
        </ng-container>
        <div class="modal-body">
          <!-- Loop through drafts and generate list items -->
          <ng-container *ngFor="let draft of drafts; let i = index">
            <div
              class="list-group-item list-group-item-action flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{ draft.title }}</h5>
              </div>
              <p class="mb-1">{{ draft.message }}</p>
              <p class="mb-1"><strong>Recipients:</strong> {{ formatRecipients(draft.recipients) }}</p>
              <!-- Buttons for edit and delete -->
              <div class="stored-save-send-container">
                <button
                  (click)="editStoredOrCustomDraft(draft)"
                  class="btn btn-danger btn-sm">
                  Edit
                </button>
                <button
                  (click)="deleteDraft(draft)"
                  class="btn btn-danger btn-sm">
                  Delete
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal for when Sent is Clicked-->
  <div #sentModal id="sentModal" class="modal">
    <div class="modal-content">
      <span class="close" (click)="closeModal(sentModal.id)">&times;</span>
      <h1>Sent</h1>
      <div class="list-group">
        <!-- Show this message if no drafts are saved-->
        <ng-container *ngIf="sent.length === 0">
          <p>No sent messages</p>
        </ng-container>
        <ng-container *ngFor="let sent of sent; let i = index">
          <div
            (click)="editStoredOrCustomDraft(sent)"
            class="list-group-item list-group-item-action flex-column align-items-start"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ sent.title }}</h5>
            </div>
            <p class="mb-1">{{ sent.message }}</p>
            <p class="mb-1"><strong>Sent to:</strong> {{ formatRecipients(sent.recipients) }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Preview Message Modal -->
  <div class="listbox-container">
    <div id="previewMessageModal" class="modal">
      <div class="modal-content">
        <span class="close" (click)="closeAllModals('previewMessageModal')"
        >&times;</span
        >
        <h2 id="mainTitle">Preview Message</h2>
        <div class="message-container">
          <div class="message-label">
            <img
              src="../assets/pencil.svg"
              alt="pencil image"
              (click)="closeAllModals('previewMessageModal'); openModal('selectUsersModal')"
            />
            <p style="padding-right: 5px"><strong>To:</strong></p>
            <p>{{ getDisplayedUsers() }}</p>
          </div>
        </div>
        <div class="message-container">
          <div class="message-label">
            <img
              src="../assets/pencil.svg"
              alt="pencil image"
              (click)="closeAllModals('previewMessageModal'); openModal('InputStoredMessageModal')"
            />
            <p><strong>Message:</strong> {{ selectedMessage }}</p>
          </div>
        </div>
        <div class="modal-body">
          <div class="stored-save-send-container">
            <button
              class="stored-save-send-buttons"
              (click)="closeAllModalsBack('previewMessageModal'); openModal('selectUsersModal')"
              id="save"
            >
              Back
            </button>
            <button
              class="stored-save-send-buttons"
              (click)="saveStoredDraft(draft)"
              id="save"
            >
              Save as Draft
            </button>
            <button
              class="stored-save-send-buttons"
              (click)="sendStoredMessage(draft)"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Preferences Page Modal-->
  <div class="listbox-container">
    <div id="filterModal" class="modal">
      <div class="modal-content">
        <span class="close" (click)="closeAllModals('filterModal')"
        >&times;</span
        >
        <h2 id="mainTitle">Filter Preferences</h2>
        <div class="row">
          <!-- Data Groups (Can change from orange to blue and vice versa depending on who logs in) -->
          <div class="col">
            <div *ngFor="let item of orangeItems; let i = index">
              {{ orangeArray[i] }}
              <ng-select [items]="item.options" bindLabel="name" bindValue="value" [multiple]="true"
                         [(ngModel)]="item.selectedOptions"
                         (change)="onItemSelect($event, item)"
              ></ng-select>
            </div>
          </div>
          <div class="stored-save-send-container">
            <button
              class="stored-save-send-buttons"
              (click)="closeAllModalsBack('filterModal'); openModal('InputStoredMessageModal')"
              id="save"
            >
              Back
            </button>
            <button
              class="stored-save-send-buttons"
              [disabled]="!isPreferenceSelected()"
              [ngClass]="{'grey-out': !isPreferenceSelected()}"
              (click)="closeAllModals('filterModal'); openModal('selectUsersModal')"
              (click)="onSelectUsersClick()"
              id="save"
            >
              Select Users
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--My Team Modal (For testing only)-->
    <div class="listbox-container">
      <div id="myTeamModal" class="modal">
        <div class="modal-content">
          <span class="close" (click)="closeAllModalsMyTeam('myTeamModal')">×</span>
          <h2 id="mainTitle">Filter Preferences</h2>
          <div class="row">
            <div class="col">
              <ngx-treeview
                class="custom-treeview"
                [items]="treeItems"
                [config]="config"
                (selectedChange)="onSelectionChange($event)"
                (toggleCollapseExpand)="onToggleCollapseExpand($event)"
              >
              </ngx-treeview>
            </div>
            <div class="stored-save-send-container">
              <button
                class="stored-save-send-buttons"
                (click)="closeAllModalsBack('myTeamModal'); openModal('InputStoredMessageModal')"
                id="save"
              >
                Back
              </button>
              <button
                class="stored-save-send-buttons"
                [disabled]="!isPreferenceSelectedMyTeam()"
                [ngClass]="{'grey-out': !isPreferenceSelectedMyTeam()}"
                (click)="closeModal('myTeamModal'); openModal('selectUsersModal')"
                id="save"
              >
                Select Users
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    

      <!-- Select Users modal -->
      <div class="listbox-container">
        <div id="selectUsersModal" class="modal">
          <div class="modal-content">
            <span class="close" (click)="closeAllModals('selectUsersModal')">×</span>
            <h2 id="mainTitle">Select Users</h2>

            <!-- Select All Checkbox -->
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                [(ngModel)]="selectAll"
                (change)="toggleSelectAll()"
                id="selectAllCheck"
              />
              <label class="form-check-label" for="selectAllCheck">
                All
              </label>
            </div>

            <!-- User Checkboxes -->
            <div class="form-check" *ngFor="let user of users">
              <input
                class="form-check-input"
                type="checkbox"
                [(ngModel)]="selectedUsers[user.empId]"
                (change)="updateSelectAllState()"
                [id]="user"
              />
              <label class="form-check-label" [for]="user">
                {{ user.agentFirstName }}, {{ user.agentLastName }} ({{ user.empId }})
              </label>
            </div>

            <div class="stored-save-send-container">
              <button
                class="stored-save-send-buttons"
                (click)="closeAllModalsBack('selectUsersModal'); openModal('filterModal')"
                id="save"
              >
                Back
              </button>
              <button
                class="stored-save-send-buttons"
                [disabled]="!isUserSelected()"
                [ngClass]="{'grey-out': !isUserSelected()}"
                (click)="closeAllModals('selectUsersModal'); openModal('previewMessageModal')"
                id="save"
              >
                Preview Message
              </button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isLoading" class="main-container mt-5">
        <div class="loading-container">
          <div class="d-flex justify-content-center">
            <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
              <circle cx="170" cy="170" r="160" stroke="#E2007C"/>
              <circle cx="170" cy="170" r="135" stroke="#404041"/>
              <circle cx="170" cy="170" r="110" stroke="#E2007C"/>
              <circle cx="170" cy="170" r="85" stroke="#404041"/>
            </svg>
          </div>
        </div>
      </div>
