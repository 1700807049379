import {UtilsService} from './../../utils/utils.service';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {TreeviewItem} from '@samotics/ngx-treeview';
import {Constants} from '../../utils/constants';
import {BackendService} from 'src/app/services/backend.service';
import {AuthService} from 'src/app/auth/auth.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {sendMessageRequest} from 'src/app/models/send-message.model';
import {AuthResponse} from 'src/app/auth/auth.model';

@Component({
  selector: 'app-directreports',
  templateUrl: './directreports.component.html',
  styleUrls: ['./directreports.component.css'],
})
export class DirectreportsComponent implements OnInit {

  filters: string[] = ['mu', 'muName', 'businessManager', 'dateofHire', 'divisionCode', 'telecommuter', 'employeeID', 'fte', 'grade', 'hours', 'jobFunction1', 'jobFunction2', 'jobFunction3', 'jobFunction4', 'jobFunction5', 'jobFunction6', 'jobFunction7', 'jobFunction8', 'jobFunction9', 'jobFunction10', 'locationCity', 'mSID', 'region', 'rTINetworkDomain', 'rTINetworkLoginID', 'gL_Code', 'supervisor', 'workStatus', 'locationFacility'];
  treeItemsBlue: TreeviewItem[] = Constants.treeItemsBlue;
  treeItemsOrange: TreeviewItem[] = Constants.treeItemsOrange

  treeItems: TreeviewItem[] = [];

  config = {
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 400,
    hasDivider: true
  };

  myForm: FormGroup;
  collapse = false;

  // Might need to change, this decides whether to open the myteam or specificusers hiearchy
  selectedModal: string = 'specificUsers';
  isLoading = true;
  // If Date and Time are invalid, these variables will be set to true
  invalidTimeInput: boolean = false; // For time invalid input
  invalidDateInput: boolean = false; // For date invalid input

  // Stored messages modal variables
  modalTitle: string = ''; //Stored Message Title
  modalContent: string = ''; //Stored Message
  selectedDate: string = ''; //Inputted Date
  selectedStartTime: string = ''; //Inputted Start Time
  selectedEndTime: string = ''; //Inputted End Time
  selectedApplication: string = ''; //Inputted Application
  selectedAction: string = ''; //Which message was selected to fill out
  messageId: string = '';
  draft: any;

  //Workstream Link variables for stored messages
  workStreamText: string = 'Hyperlink to ‘schedule change request’ within ';
  workStreamLink: string = '';

  // Lists for stored messages
  times: string[] = []; // List of times
  applications: string[] = ['Attendance Updater Tool', 'sparq']; // List of applications

  draftsCount: number = 0;
  sentCount: number = 0;
  selectedMessage: string = '';

  savedUsers: string[] = [];
  selectedUsers: { [user: string]: boolean } = {};

  temp: { type: string; title: string; message: string }[] = [];

  drafts: { type: string; title: string; message: string; messageId: string; recipients: string[] }[] = [];

  sent: {
    recipients: any; type: string; title: string; message: string
  } [] = [];

  users: {
    empId: string, agentFirstName: string, agentLastName: string
  } [] = [];

  selectAll: boolean = true;


  blueArray = Constants.BLUE_ARRAY;
  orangeArray = Constants.ORANGE_ARRAY;
  myTeamArray = Constants.MY_TEAM_ARRAY;

  // For Preferences Page
  orangeItems: any[] = [];
  blueItems: any[] = [];
  myTeamItems = Constants.MY_TEAM_ITEMS;

  role: string = '';
  instance: string = '';

  selectElements: any[] = [];
  showError: boolean = false; // New property to track error state


  isCurrentlyEditingStoredDraft: boolean = false;
  isCurrentlyEditingStoredDraftIndex: number = -1;

  userData: AuthResponse | null = null;

  constructor(private cdr: ChangeDetectorRef, private authService: AuthService, private httpClient: HttpClient, private utilsService: UtilsService, private router: Router, private backendService: BackendService) {
    this.myForm = new FormGroup({
      userTitle: new FormControl(''), userMessage: new FormControl(''),
    });

    // The link and formatting it so it appears as "Webstation" and is clickable on the stored message screen
    this.workStreamLink = `<a href="${'https://wfmuhc.uhc.com/wfm/webstation/my-schedule-changes'}" target="_blank">${'Webstation'}</a>`;
    this.workStreamText += this.workStreamLink;
  }

  async ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search);
    const oboToken = urlParams.get('access_token');
    if (!oboToken) {
        if (this.authService.isAuthenticated()) {
            this.fetchMessageCounts();
            this.fetchInstance().then(() => {
                this.fetchFilters();
            });
            this.isLoading = false;
        } else {
            if (this.authService.isAuthenticatedOBO()) {
                this.userData = this.authService.getOBOUserDataStorage();
                console.log(this.userData);
                this.fetchMessageCounts();
                this.fetchFilters();
                this.isLoading = false;
            } else {
                this.router.navigate(['/error'], { queryParams: { errorCode: 401 } });
            }
        }
    }
}
  // MY TEAM MODAL

  fetchMessageCounts(): void {
    console.log(this.authService.getIdentityClaims().employeeId);
    this.backendService.getMessagesCount(this.authService.getIdentityClaims().employeeId).subscribe(data => {
      console.log(data)
      this.draftsCount = data.draftsCount;
      this.sentCount = data.savedCount;
    }, error => {
      console.error('Error calling backend:', error);
    });
  }

  // SPECIFIC USERS MODAL

  fetchRole(): Promise<void> {
    console.log("in fetch role")
    console.log(this.authService.getIdentityClaims().employeeId)
    return this.backendService.getRole(this.authService.getIdentityClaims().employeeId).toPromise()
      .then(data => {
        console.log(data);
        this.role = data.role;
        console.log(this.role);
      }, error => {
        console.error('Error calling backend:', error);
      });
  }

  //  NEW MESSAGE MODAL

  fetchInstance(): Promise<void> {
    console.log("in fetch instance")
    console.log(this.authService.getIdentityClaims().employeeId)
    return this.backendService.getInstance(this.authService.getIdentityClaims().employeeId).toPromise()
      .then(data => {
        console.log(data);
        this.instance = data.instance;
        console.log(this.instance);
      }, error => {
        console.error('Error calling backend:', error);
      });
  }

  // Function to determine which button was clicked and the message that will be shown (1)
  storedMessageInputPopup(action: string, draft: any): void {
    this.selectedAction = action;
    this.draft = draft;
    console.log('inside storedmessageInputpopup', this.isCurrentlyEditingStoredDraft, this.isCurrentlyEditingStoredDraftIndex);

    switch (action) {
      case 'extraTime':
        this.modalTitle = 'Extra Time/OT is Available';
        this.modalContent = 'Extra Time/Overtime is available on [date] between [time] and [time]. Please submit schedule change requests for review.';
        this.openModal('InputStoredMessageModal');
        this.closeModal('storedMessageSelection')
        // this.modalContent = 'Extra Time/Overtime is available on [date] between [time] and [time]. Please submit schedule change requests for review.';
        break;
      case 'vto':
        this.modalTitle = 'VTO is Available';
        this.modalContent = 'Voluntary Time Off is available on [date] between [time] and [time]. Please submit schedule change requests for review.';
        this.openModal('InputStoredMessageModal');
        this.closeModal('storedMessageSelection')
        // this.modalContent = 'Voluntary Time Off is available on [date] between [time] and [time]. Please submit schedule change requests for review.';
        break;
      case 'sysOutage':
        this.modalTitle = 'System Outage Notification';
        this.messageId = draft.messageId;
        this.openModal('InputStoredMessageModal');
        this.closeModal('storedMessageSelection')
        console.log('In modal pop')
        this.modalContent = 'We are aware of an active system outage for [application]. You no longer need to contact the Help Desk to advise';
        break;
    }
  }

  // We ARE NOT converting it to AM PM Format from Military Format here
  updateStartTime(event: Event): void {
    this.selectedStartTime = (event.target as HTMLInputElement).value;
    console.log(this.selectedStartTime);
    if (this.selectedEndTime !== '') {
      const [startHours, startMinutes] = this.selectedStartTime
        .split(':')
        .map(Number);
      const [endHours, endMinutes] = this.selectedEndTime
        .split(':')
        .map(Number);
      if (startHours > endHours || (startHours === endHours && startMinutes > endMinutes)) {
        this.invalidTimeInput = true;
      } else {
        this.invalidTimeInput = false;
      }
      if (this.selectedStartTime === this.selectedEndTime) {
        this.invalidTimeInput = true;
      }
    }
  }

  // We ARE NOT converting it to AM PM Format from Military Format here
  updateEndTime(event: Event): void {
    this.selectedEndTime = (event.target as HTMLInputElement).value;
    // console.log(this.selectedEndTime);

    if (this.selectedStartTime !== '') {
      const [startHours, startMinutes] = this.selectedStartTime
        .split(':')
        .map(Number);
      const [endHours, endMinutes] = this.selectedEndTime
        .split(':')
        .map(Number);
      if (startHours > endHours || (startHours === endHours && startMinutes > endMinutes)) {
        this.invalidTimeInput = true;
      } else {
        this.invalidTimeInput = false;
      }
      if (this.selectedStartTime === this.selectedEndTime) {
        this.invalidTimeInput = true;
      }
    }
  }

  // Function to store the inputted date
  updateDate(event: Event): void {
    this.selectedDate = (event.target as HTMLInputElement).value;
    const today = new Date();
    if (this.selectedDate < today.toISOString().split('T')[0]) {
      console.log('Cannot choose a day in the past');
      this.invalidDateInput = true;
    } else {
      this.invalidDateInput = false;
    }
  }

  // Function to save users that are selected by the user into an array
  saveUsersMyTeamModal() {
    this.savedUsers = []; // Clears whatever was saved before in the array so only new selection is saved
    const checkboxes = document.querySelectorAll('.form-check-input');
    checkboxes.forEach((checkbox) => {
      if (checkbox instanceof HTMLInputElement) {
        if (checkbox.checked) {
          const label = checkbox.nextElementSibling as HTMLLabelElement; // Getting data from 'label' element
          if (label) {
            const user = label.textContent?.trim();
            if (user) {
              this.savedUsers.push(user);
            }
          }
        }
      }
    });
    this.closeModal('myTeamModal');
  }

  // Function to save users that are selected by the user into an array
  saveUsersSpecificUsersModal() {
    this.savedUsers = []; // Clears whatever was saved before in the array so only new selection is saved
    const checkboxes = document.querySelectorAll('.form-check-input');
    checkboxes.forEach((checkbox) => {
      if (checkbox instanceof HTMLInputElement) {
        if (checkbox.checked) {
          const label = checkbox.nextElementSibling as HTMLLabelElement;
          if (label) {
            const user = label.textContent?.trim();
            if (user) {
              this.savedUsers.push(user);
            }
          }
        }
      }
    });
    console.log('Selected Users:', this.savedUsers);
    this.closeModal('specificUsersModal');
  }

  // STORED MESSAGE MODAL

  openModal(modalId: string): void {
    const modal = document.getElementById(modalId);
    if (modalId.match('sentModal')) {
      console.log("in sent");
      this.fetchSentMessages();
    }

    if (modalId.match('draftsModal')) {
      console.log("in draft");
      this.fetchDraftMessages();
    }

    if (modalId.match('myTeamModal')) {
      console.log("in myTeamModal");
      this.fetchReportes(this.authService.getIdentityClaims().employeeId);
    }

    modal!.style.display = 'block';
  }

  // When the user sends messages or saves to drafts

  closeModal(modalId: string) {
    const modal = document.getElementById(modalId);
    modal!.style.display = 'none';
  }

  // DRAFTS MODAL

  closeAllModals(modalId: string) {
    console.log('in close')
    const modal = document.getElementById(modalId);
    modal!.style.display = 'none';
    //this.selectedApplication = '';
    //this.selectedDate = '';
    //this.selectedStartTime = '';
    //this.selectedEndTime = '';
  }

  closeAllModalsMyTeam(modalId: string) {
    const modal = document.getElementById(modalId);
    modal!.style.display = 'none';
    for (let item of this.myTeamItems) {
      item.selectedOptions = [];
    }
    this.selectedApplication = '';
    this.selectedDate = '';
    this.selectedStartTime = '';
    this.selectedEndTime = '';
  }

  closeAllModalsBack(modalId: string) {
    const modal = document.getElementById(modalId);
    modal!.style.display = 'none';
  }

  // STORED MESSAGE MODAL
  closeStoredMessageInputPopup(): void {
    if (this.isCurrentlyEditingStoredDraft === true && this.isCurrentlyEditingStoredDraftIndex !== -1) {
      this.isCurrentlyEditingStoredDraft = false;
      this.isCurrentlyEditingStoredDraftIndex = -1;
      this.clearFields();
    }
    console.log('inside closestoredmessageinput', this.isCurrentlyEditingStoredDraft, this.isCurrentlyEditingStoredDraftIndex);
    const modal = document.getElementById('InputStoredMessageModal');
    if (modal) {
      modal.style.display = 'none';
    }
  }

  // then both the stored message selection and the stored message input screen will close
  closeInputedStoredMessageAndSelection(): void {
    this.closeModal('InputStoredMessageModal');
    this.closeModal('storedMessageSelection');
    this.closeModal('previewMessageModal');
  }

  // When the Sent Button is clicked in "Drafts", display the message in the Sent Folder

  // Checking whether the draft is stored or custom and calling the correct function to edit
  editStoredOrCustomDraft(draft: any): void {

    console.log("in draft edit");
    // Store the draft index and set the editing Stored draft variable to true
    // this.isCurrentlyEditingStoredDraftIndex = index;

    // Set the draft index, title, and message to the selected stored draft
    const title = draft.title;
    const message = draft.message;
    const messageId = draft.messageId;
    // Set the action based on the title so we can feed this action into our storedMessageInputPopup function
    if (title.includes('Extra Time/OT')) {
      this.selectedAction = 'extraTime';
    }
    if (title.includes('VTO')) {
      this.selectedAction = 'vto';
    }
    if (title.includes('System Outage')) {
      this.selectedAction = 'sysOutage';
    }
    // Parse the stored draft message to get the date, start time, and end time or application name
    this.parseStoredDraftMessage(title, message);
    // Put that information through to the stored message input popup
    this.storedMessageInputPopup(this.selectedAction, draft);
    this.closeModal('draftsModal');
  }

  // Saves the draft locally when user clicks "Save as Draft"
  saveDraft(): void {
    // If the user is editing a custom draft then remove the old draft and add the new one else just add the new custom draft
    console.log('inside saveDraft');
    // If the user is not editing a custom draft then add the new custom draft
    const newDraft: {
      type: string; title: string; message: string
    } = {
      type: 'custom',
      title: this.myForm.controls['userTitle'].value,
      message: this.myForm.controls['userMessage'].value,
    };
    this.myForm.reset();
    this.closeModal("sentModal");
  }

  // Saves the stored draft locally when user clicks "Save as Draft"
  saveStoredDraft(draft: any): void {
    console.log('draft');
    // Convert the start and end times to AM PM format
    if (this.selectedAction === 'extraTime' || this.selectedAction === 'vto') {
      if (this.selectedStartTime !== '') {
        //Formatting the start time to AM PM
        const [startHours, startMinutes] = this.selectedStartTime
          .split(':')
          .map(Number);
        const startPeriod = startHours < 12 ? 'AM' : 'PM';
        const startHours12 = startHours % 12 || 12;
        this.selectedStartTime = `${startHours12}:${startMinutes
          .toString()
          .padStart(2, '0')} ${startPeriod}`;
      }
      if (this.selectedEndTime !== '') {
        //Formatting the end time to AM PM
        const [endHours, endMinutes] = this.selectedEndTime
          .split(':')
          .map(Number);
        const endPeriod = endHours < 12 ? 'AM' : 'PM';
        const endHours12 = endHours % 12 || 12;
        this.selectedEndTime = `${endHours12}:${endMinutes
          .toString()
          .padStart(2, '0')} ${endPeriod}`;
      }
    }

    let messageToBeDrafted: any;

    switch (this.selectedAction) {
      case 'extraTime':
        const newExtraTimeDraft: {
          type: string; title: string; message: string;
        } = {
          type: 'stored',
          title: this.modalTitle,
          message: `Extra Time/Overtime is available on ${this.selectedDate} between ${this.selectedStartTime} and ${this.selectedEndTime} (CST). Please submit schedule change requests for review. ${this.workStreamText}`,
        };
        messageToBeDrafted = newExtraTimeDraft;
        break;
      case 'vto':
        const newVtoDraft: { type: string; title: string; message: string } = {
          type: 'stored',
          title: this.modalTitle,
          message: `Voluntary Time Off is available on ${this.selectedDate} between ${this.selectedStartTime} and ${this.selectedEndTime} (CST). Please submit schedule change requests for review. ${this.workStreamText}`,
        };
        messageToBeDrafted = newVtoDraft;
        break;
      case 'sysOutage':
        const newSysOutageDraft: {
          type: string; title: string; message: string;
        } = {
          type: 'stored',
          title: '1',
          message: `We are aware of an active system outage for ${this.selectedApplication}. You no longer need to contact the Help Desk to advise.`,
        };
        messageToBeDrafted = newSysOutageDraft;
        break;
    }

    const request: any = {
      employeeId: this.authService.getIdentityClaims().employeeId,
      messageId: draft.messageId != null ? draft.messageId : '',
      storedMessageId: messageToBeDrafted.title,
      messageText: messageToBeDrafted.message,
      recipientEmployees: this.getSelectedReceipients(),
      isFinal: false,
    }
    console.log(request);

    this.backendService.sendMessage(request).subscribe(response => {
    }, error => {
      console.error(error);
    })

    this.selectedUsers = {};
    this.clearFields(); // Clear selected values
    this.fetchMessageCounts();
    this.isCurrentlyEditingStoredDraft = false;
    this.isCurrentlyEditingStoredDraftIndex = -1;

    // To navigate back to the main page
    this.closeInputedStoredMessageAndSelection();
    this.closeModal('newMessageModal');
  }

  parseStoredDraftMessage(title: string, message: string): void {
    const draftTitle = title;
    const draftMessage = message;
    if (draftTitle.includes('Extra Time/OT') || draftTitle.includes('VTO')) {
      // Parsing the date from the message
      const messageSplit = draftMessage.split(' ');
      const dateIndex = messageSplit.indexOf('on') + 1;
      const date = messageSplit[dateIndex];
      this.selectedDate = date;

      // Parsing the time from the message
      // WE NEED TO CONVERT AM PM FORMAT TO MILTARY TIME BECAUSE OF THE TIME DISPLAY WE ARE USING
      const betweenIndex = messageSplit.indexOf('between');
      const andIndex = messageSplit.indexOf('and');
      let startTime = '';
      let startAmPm = '';
      let endTime = '';
      let endAmPm = '';

      // The start time
      if (betweenIndex !== -1 && betweenIndex < andIndex) {
        startTime = messageSplit[betweenIndex + 1];
        startAmPm = messageSplit[betweenIndex + 2];

        // if start time is AM then we are formatting it to military time
        if (startAmPm === 'AM') {
          const [hours, minutes] = startTime.split(':').map(Number);
          if (hours === 12) {
            this.selectedStartTime = `00:${minutes}`;
          } else {
            const hoursMinutes = startTime.split(':');
            let hours = parseInt(hoursMinutes[0], 10)
              .toString()
              .padStart(2, '0');
            const minutes = parseInt(hoursMinutes[1], 10)
              .toString()
              .padStart(2, '0');
            this.selectedStartTime = `${hours}:${minutes}`;
          }
        }
        // if start time is PM then we are formatting it to military time by adding 12 hours
        if (startAmPm === 'PM') {
          const [hours, minutes] = startTime.split(':').map(Number);
          const militaryHours = hours + 12;
          this.selectedStartTime = `${militaryHours}:${minutes}`;
        }
      }

      // The end time
      if (andIndex !== -1 && andIndex > betweenIndex) {
        endTime = messageSplit[andIndex + 1];
        endAmPm = messageSplit[andIndex + 2];

        // if end time is AM then we are formatting it to military time
        if (endAmPm === 'AM') {
          const [hours, minutes] = endTime.split(':').map(Number);
          if (hours === 12) {
            this.selectedEndTime = `00:${minutes}`;
          } else {
            const hoursMinutes = endTime.split(':');
            let hours = parseInt(hoursMinutes[0], 10)
              .toString()
              .padStart(2, '0');
            const minutes = parseInt(hoursMinutes[1], 10)
              .toString()
              .padStart(2, '0');
            this.selectedEndTime = `${hours}:${minutes}`;
          }
        }
        // if end time is PM then we are formatting it to military time by adding 12 hours
        if (endAmPm === 'PM') {
          const [hours] = endTime.split(':').map(Number);
          const hoursMinutes = endTime.split(':');
          const militaryHours = hours + 12;
          const militaryMinutes = parseInt(hoursMinutes[1], 10)
            .toString()
            .padStart(2, '0');
          this.selectedEndTime = `${militaryHours}:${militaryMinutes}`;
        }
      }
    } else {
      console.log('in pop up edit ')
      // If stored sysOutage message then we are getting the application name between "for" and "."
      const regex = /for\s+(.*?)\./;
      const match = regex.exec(message);
      console.log('testing match:', match);
      if (match && match.length > 1) {
        this.selectedApplication = match[1].trim();
      }
    }
  }

  // When Sent button is clicked in "New Message", display the message in the Sent folder
  sendStoredMessage(draft: any): void {
    console.log("Inside send method");
    // Convert the start and end times to AM PM format
    if (this.selectedAction === 'extraTime' || this.selectedAction === 'vto') {
      if (this.selectedStartTime !== '') {
        //Formatting the start time to AM PM
        const [startHours, startMinutes] = this.selectedStartTime
          .split(':')
          .map(Number);
        const startPeriod = startHours < 12 ? 'AM' : 'PM';
        const startHours12 = startHours % 12 || 12;
        this.selectedStartTime = `${startHours12}:${startMinutes
          .toString()
          .padStart(2, '0')} ${startPeriod}`;
      }
      if (this.selectedEndTime !== '') {
        //Formatting the end time to AM PM
        const [endHours, endMinutes] = this.selectedEndTime
          .split(':')
          .map(Number);
        const endPeriod = endHours < 12 ? 'AM' : 'PM';
        const endHours12 = endHours % 12 || 12;
        this.selectedEndTime = `${endHours12}:${endMinutes
          .toString()
          .padStart(2, '0')} ${endPeriod}`;
      }
    }

    let messageToBeSent: any;

    const selectedUsers = Object.keys(this.selectedUsers).filter(user => this.selectedUsers[user]);
    switch (this.selectedAction) {
      case 'extraTime':
        const newExtraTimeDraft: {
          type: string; title: string; message: string;
        } = {
          type: 'stored',
          title: this.modalTitle,
          message: `Extra Time/Overtime is available on ${this.selectedDate} between ${this.selectedStartTime} and ${this.selectedEndTime} (CST). Please submit schedule change requests for review. ${this.workStreamText}`,
        };
        break;
      case 'vto':
        const newVtoDraft: { type: string; title: string; message: string } = {
          type: 'stored',
          title: this.modalTitle,
          message: `Voluntary Time Off is available on ${this.selectedDate} between ${this.selectedStartTime} and ${this.selectedEndTime} (CST). Please submit schedule change requests for review. ${this.workStreamText}`,
        };
        break;
      case 'sysOutage':
        const newSysOutageDraft: {
          type: string; title: string; message: string; recipients: string[];
        } = {
          type: 'stored',
          title: '1',
          message: `We are aware of an active system outage for ${this.selectedApplication}. You no longer need to contact the Help Desk to advise.`,
          recipients: this.getSelectedReceipients()
        };
        messageToBeSent = newSysOutageDraft;
        break;
    }

    const request: sendMessageRequest = {
      employeeId: this.authService.getIdentityClaims().employeeId,
      messageId: draft.messageId != null ? draft.messageId : '',
      storedMessageId: messageToBeSent.title,
      messageText: messageToBeSent.message,
      recipientEmployees: messageToBeSent.recipients,
      isFinal: true,
    }

    this.backendService.sendMessage(request).subscribe(response => {
    }, error => {
      console.error(error);
    })

    this.clearFields(); // Clear selected values
    this.fetchMessageCounts();
    // To navigate back to the main page
    this.closeInputedStoredMessageAndSelection();
    this.closeModal('closeNewMessageModal');
  }

  deleteDraft(draft: any): void {
    this.deleteDraftMessage(draft.messageId);
    this.fetchDraftMessages();
  }

  /*isUserSelected()
    :
    boolean {
    for (let user in this.selectedUsers) {
      if (this.selectedUsers[user]) {
        return true;
      }
    }
    return false;
  }*/

  editStoredDraft(index: number): void {
    // Store the draft index and set the editing Stored draft variable to true
    this.isCurrentlyEditingStoredDraftIndex = index;
    this.isCurrentlyEditingStoredDraft = true;

    // Set the draft index, title, and message to the selected stored draft
    const selectedDraft = this.drafts[index];
    const title = selectedDraft.title;
    const message = selectedDraft.message;
    // Set the action based on the title so we can feed this action into our storedMessageInputPopup function
    if (title.includes('Extra Time/OT')) {
      this.selectedAction = 'extraTime';
    }
    if (title.includes('VTO')) {
      this.selectedAction = 'vto';
    }
    if (title.includes('System Outage')) {
      this.selectedAction = 'sysOutage';
    }
    // Parse the stored draft message to get the date, start time, and end time or application name
    this.parseStoredDraftMessage(title, message);
    // Put that information through to the stored message input popup
    this.storedMessageInputPopup(this.selectedAction, '');
    this.closeModal('draftsModal');
  }

  // Clearing all the fields for the next time the user inputs into the stored message
  clearFields(): void {
    this.selectedAction = ''; // Reset selected action
    this.selectedDate = ''; // Reset selected date
    this.selectedStartTime = ''; // Reset selected start time
    this.selectedEndTime = ''; // Reset selected end time
    this.selectedApplication = ''; // Reset selected application
  }

  onItemSelect(selectedItems: any[], item: any) {
    if (selectedItems.some(i => i.value === 'all')) {
      // If 'Select All' is selected, select all other options
      item.selectedOptions = item.options.filter((option: {
        value: string;
      }) => option.value !== 'all').map((option: {
        value: any;
      }) => option.value);
      // Remove 'Select All' from the selection
      const index = item.selectedOptions.indexOf('all');
      if (index > -1) {
        item.selectedOptions.splice(index, 1);
      }
    }

  }

  updateSelectedMessage() {
    if (this.selectedAction === 'sysOutage') {
      this.selectedMessage = `We are aware of an active system outage for ${this.selectedApplication}. You no longer need to contact the Help Desk to advise`;
    }
    if (this.selectedAction === 'vto') {
      this.selectedMessage = `Voluntary Time Off is available on ${this.selectedDate} between ${this.selectedStartTime} and ${this.selectedEndTime} (CST). Please submit schedule change requests for review.`;
    }
    if (this.selectedAction === 'extraTime' || this.selectedAction === 'ot') {
      this.selectedMessage = `Extra Time/Overtime is available on ${this.selectedDate} between ${this.selectedStartTime} and ${this.selectedEndTime} (CST). Please submit schedule change requests for review.`;
    }
    this.cdr.detectChanges();
  }

  loadFilters(data: string[]) {

  }

  getDisplayedUsers() {
    const selectedUserKeys = Object.keys(this.selectedUsers).filter(key => this.selectedUsers[key]);
    const displayedUsers = selectedUserKeys.map(key => {
      const user = this.users.find((u: any) => u.empId === key);
      return user ? `${user.agentLastName}, ${user.agentFirstName}` : '';
    }).filter(Boolean);

    if (displayedUsers.length > 5) {
      return `${displayedUsers.slice(0, 5).join('; ')} and ${displayedUsers.length - 5} more`;
    } else {
      return displayedUsers.join('; ');
    }
  }

  getSelectedReceipients() {
    return Object.keys(this.selectedUsers).filter(key => this.selectedUsers[key]);
  }

  isPreferenceSelected(): boolean {
    for (let item of this.orangeItems) {
      if (item.selectedOptions && item.selectedOptions.length > 0) {
        return true;
      }
    }
    return false;
  }

  isPreferenceSelectedMyTeam(): boolean {
    for (let item of this.myTeamItems) {
      if (item.selectedOptions && item.selectedOptions.length > 0) {
        console.log(item.selectedOptions)
        return true;
      }
    }
    return false;
  }

  formatRecipients(recipients: string[]): string {
    if (recipients.length <= 3) {
      return recipients.join('; ');
    } else {
      return `${recipients.slice(0, 3).join('; ')}; +${recipients.length - 3}`;
    }
  }

  isVtoValid(): boolean {
    const isDateValid = this.selectedDate !== '' && !this.invalidDateInput;
    const isStartTimeValid = this.selectedStartTime !== '' && !this.invalidTimeInput;
    const isEndTimeValid = this.selectedEndTime !== ''; // Add validation if necessary

    return isDateValid && isStartTimeValid && isEndTimeValid;
  }

  isExtraTimeValid(): boolean {
    const isDateValid = this.selectedDate !== '' && !this.invalidDateInput;
    const isStartTimeValid = this.selectedStartTime !== '' && !this.invalidTimeInput;
    const isEndTimeValid = this.selectedEndTime !== ''; // Add validation if necessary

    return isDateValid && isStartTimeValid && isEndTimeValid;
  }

  onSelectUsersClick() {
    this.selectedUsers = {};
    const selectedFilters: { [key: string]: string[] } = {};
    this.orangeItems.forEach(item => {
      if (item.selectedOptions.length > 0) {
        selectedFilters[item.filter] = item.selectedOptions.map((opt: string) => opt);
      }
    });
    this.getAgents(selectedFilters);
  }

  initializeSelectedUsers() {
    this.users.forEach(user => {
      this.selectedUsers[user.empId] = true;
    });
  }

  toggleSelectAll() {
    this.users.forEach(user => this.selectedUsers[user.empId] = this.selectAll);
  }

  updateSelectAllState() {
    // Check if all users are selected
    const allSelected = this.users.every(user => this.selectedUsers[user.empId]);

    // Update the selectAll checkbox state
    this.selectAll = allSelected;
  }

  isUserSelected(): boolean {
    return Object.values(this.selectedUsers).some(selected => selected);
  }

  onSelectionChange(item: any) {
    console.log("in change")
  }

  fetchDraftMessages() {
    this.drafts.splice(0);
    this.backendService.getDraftMessages(this.authService.getIdentityClaims().employeeId).subscribe(data => {
      data.forEach(message => {
        const tempMessage: {
          type: string; title: string; message: string; messageId: string; recipients: string[];
        } = {
          type: 'stored',
          title: message.messageTitle,
          message: message.messageText,
          messageId: message.messageId,
          recipients: message.recipients
        };
        this.drafts.unshift(tempMessage);
      });
    }, error => {
      console.error('Error calling backend:', error);
    });
  }

  fetchSentMessages() {
    this.sent.splice(0);
    this.backendService.getSentMessages(this.authService.getIdentityClaims().employeeId).subscribe(data => {
      data.forEach(message => {
        const tempMessage: {
          type: string; title: string; message: string; recipients: any;
        } = {
          type: 'stored', title: message.title, message: message.messageText, recipients: message.recipients
        };
        this.sent.unshift(tempMessage);
      });
    }, error => {
      console.error('Error calling backend:', error);
    });
  }

  deleteDraftMessage(messageId: string) {
    this.backendService.deleteDraftMessage(messageId).subscribe(data => {
    }, error => {
      console.error('Error calling backend:', error);
    });
  }

  fetchReportes(employeeId: string) {
    this.backendService.getReportes(employeeId).subscribe(data => {
      console.log(data);

      const childrenArray = data.map(employee => {
        return {
          text: `${employee.FirstName} ${employee.LastName}`,
          value: employee.EmployeeIdentifier,
          collapsed: true,
          children: [] // Initialize with an empty array for children
        };
      });

      const parentNode = new TreeviewItem({
        text: 'dummy, employee', value: employeeId, collapsed: true, children: childrenArray
      });

      this.treeItems.unshift(parentNode);
    }, error => {
      console.error('Error calling backend:', error);
    });
  }

  onToggleCollapseExpand(event: any) {
    console.log('onToggleCollapseExpand called');
    const node = event as TreeviewItem; // Cast the event to TreeviewItem
    if (!node.collapsed && node.children.length === 0) {
      this.backendService.getReportes(node.value).subscribe(data => {
        console.log('Fetched children:', data); // Log the fetched data
        const childrenArray = data.map(employee => {
          return new TreeviewItem({
            text: `${employee.FirstName} ${employee.LastName}`,
            value: employee.EmployeeIdentifier,
            collapsed: true,
            children: []
          });
        });
        node.children = childrenArray;
        console.log('Updated node with children:', node); // Log the updated node
        this.treeItems = [...this.treeItems]; // Trigger change detection
      }, error => {
        console.error('Error fetching children:', error);
      });
    }
  }

  getAgents(selectedFilters: any) {
    const jsonString = JSON.stringify(selectedFilters);
    console.log("in fetch agents")
    this.users = [];
    this.backendService.getAgents(jsonString).subscribe(data => {
      data.forEach((user) => {
        const tempuser: {
          empId: string, agentFirstName: string, agentLastName: string
        } = {
          empId: user.empId, agentFirstName: user.agentFirstName, agentLastName: user.agentLastName
        };
        console.log(tempuser)
        this.users.unshift(tempuser);
        this.initializeSelectedUsers();
      })
    }, error => {
      console.error('Error calling backend:', error);
    });
  }

  private async fetchFilters() {
    // Create an array of promises
    const filterPromises = this.filters.map(filter => this.backendService.getFilters(filter).toPromise()
      .then(data => {
        const options = data.map((item: string) => ({
          name: item, value: item // Convert to string to match your previous example
        }));

        return {
          filter: filter, options: [{name: 'Select All', value: 'all'}, ...options], selectedOptions: []
        };
      })
      .catch(error => {
        console.error(`Error calling backend for filter ${filter}:`, error);
        // Return a default or empty option set in case of error
        return {
          options: [{name: 'Select All', value: 'all'}], selectedOptions: []
        };
      }));

    try {
      // Wait for all promises to resolve
      const results = await Promise.all(filterPromises);

      // Clear existing items and add new ones in order
      this.orangeItems = results;

    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  }
}
