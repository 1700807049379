export const environment = {
    name: 'nonprod',
    production: true,
    authConfig: {
      issuer: 'https://authgateway3-stg.entiam.uhg.com',
      redirectUri: window.location.origin,
      clientId: 'Reg3Stg_WFMobilityUI',
      customQueryParams: {
        acr_values: 'R3_AAL2_RBA_EXT_INT',
      },
      responseType: 'code',
      scope: 'openid profile email phone',
    }
};